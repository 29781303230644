import React from "react";
import { createRoot } from 'react-dom/client';
import SubmissionTrackingService from "./components/SubmissionTrackingService";

const getUuidFromUrl = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return params.uuid;
}


if (module.hot) {
  module.hot.accept();
}

var mountNode = document.getElementById("app");
const root = createRoot(mountNode);
root.render(<SubmissionTrackingService uuid={getUuidFromUrl()} />);